details {
  margin-bottom: 5px;
}

summary {
  color: var(--bs-primary);
}

#warning-div {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

#warning-div.warning-active {
  visibility: visible;
  opacity: 1;
}

#error-overlay {
  display: block;
  opacity: 0.6;
  box-sizing: border-box;
  position: fixed;
  padding:0;
  margin:0;
  z-index: 2000;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: black;
}

#warning-bar {
  background: var(--bs-danger);
  text-align: center;
  z-index: 3000;
}

.table {
  width: auto;
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.schedule-container {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  white-space: nowrap;
  column-gap: 5px;
  row-gap: 10px;
  padding-top: 15px;
}

.dayButton {
  background-color: white;
  border-radius: 100vh;
  border-style: solid;
  border-width: 1.5px;
  width: 26px;
  height: 26px;
  font-size: smaller;
  font-weight: 500;
  padding: 0;
  margin-left: 3px;
  margin-right: 3px;
  color: darkgrey;
  border-color: darkgrey;
}

.dayButtonActive {
  color: dodgerblue;
  border-color: dodgerblue;
}

.errorButton {
  visibility: hidden;
}

h5 {
  margin: 0;
}

h6 {
  margin: 0;
}

.modal-dialog {
  display: flex;
  min-height: 700px;
  height: calc(100% - var(--bs-modal-margin)*2);
}

.modal-body,
#modal-form,
#editor-form-group,
#monaco-editor-container {
  min-height: 0;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.details-text {
  border-left-color: rgb(216, 221, 224);
  border-left-style: solid;
  border-left-width: 4px;
  padding-left: 12px;
}

.tooltip {
  --bs-tooltip-bg: var(--bs-red);
  --bs-tooltip-max-width: 500px;
  --bs-tooltip-opacity: 100;
  white-space: pre-line;
}

.tooltip-inner {
  text-align: left;
}

.editButton {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: dodgerblue;
  visibility: hidden;
}

.editButton:hover {
  color: #0066ff;
}

.buttonParent:hover .editButton {
  visibility: visible;
}

.deleteButton {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: indianred;
  visibility: hidden;
}

.deleteButton:hover {
  color: red;
}

.buttonParent:hover .deleteButton {
  visibility: visible;
}

.activeDiv {
  display:flex;
  justify-content:left;
}

.errorButton {
  border: none;
  background: none;
  margin: 0;
  padding: 0 4px 0 0;
  color: var(--bs-red);
  cursor: default !important;
}

.activeButton {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: gray;
  visibility: hidden;
}

.activeButton .bi-stop-fill {
  display: none;
  color: #464646;
}

.activeButton.active {
  visibility: visible;
  color: #464646;
}

.activeButton.active:hover .bi-stop-fill {
  display: inline-block;
  visibility: visible;
  color: #464646;
}

.activeButton.active:hover .bi-play-fill {
  display: none;
  color: #464646;
}

.activeButton:hover {
  color: #464646;
}

.buttonParent:hover .activeButton {
  visibility: visible;
}

.actionDiv {
  display: flex;
  justify-content: space-around;
}

.main-title {
  font-family: "Oswald", sans-serif;
  font-style: italic;
  text-align: center;
  font-size: 500%;
  text-shadow: 3px 2px red, 5px 4px #00ff00;
  color: blue;
}

#schedule-button {
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border-radius: 100vh;
  border: none;
  /*border: 1.6px solid gray;*/
  font-size: 14px;
  /*color: #656565;*/
  /*color: #1c73f5;*/
  /*color: grey;*/
  color: rgba(0, 0, 255, 0.65);
}

#schedule-button:hover {
  /*color: #808080;*/
  /*color: #005bde;*/
  /*color: #616161;*/
  color: rgba(0, 0, 255, 0.88);
}

#schedule-button.schedule-error {
  color: grey;
}

table {
  color: #464646 !important;
}

#addButton {
  border: none;
  background: none;
  padding: 0;
  color: gray;
  margin: -20px auto 0;
  font-size: 40px;
}

#addButton:hover {
  color: #464646;
}